<template>
  <div class="chartWrapper">
    <div class="userStats">
    <h1>Users Stats</h1>
    <div class="filterWrapper">
      <base-locations v-model="locObject" @input="updateUserStats"></base-locations>
      <div class="filters__item">
      <date-range-picker
        ref="picker"
        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
        :showDropdowns="true"
        :autoApply="true"
        v-model="dateRange"
        :linkedCalendars="true"
        :dateFormat="dateFormat"
        @select="selectDate"
      >
      </date-range-picker>
      </div>
    </div>
  </div>
  <div id="chartdiv"></div>
  <div></div>
  </div>
</template>

<script>
/**/
let date = new Date();
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
/**/
import { USERS_STATS } from "@/graphql/common/query";
// chart
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      locObject: null,
      location_id: null,
      dateRange: { startDate, endDate },
      filters: {
        startDate: new Date(startDate).getTime().toString(),
        endDate: new Date(endDate).getTime().toString(),
        idLocation: 0,
      },
      userStatsData: "",
    }
  },
  created() {
    this.updateUserStats();
  },
  mounted() {
      
      let chart = am4core.create("chartdiv", am4charts.XYChart);

      chart.marginRight = 500;
      chart.data = this.userStatsData;

      // chart.scrollbarX = new am4core.Scrollbar();
      // chart.scrollbarY = new am4core.Scrollbar();
      chart.padding(40, 40, 40, 40);
      chart.legend = new am4charts.Legend();

      chart.responsive.enabled = true;

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.title.text = "Date";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 60;
      
      var  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "Count";

      // Create series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "accountCount";
      series.dataFields.categoryX = "date";
      series.name = "Total Accounts Registerd";
      
      var series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.dataFields.valueY = "instargramCount";
      series1.dataFields.categoryX = "date";
      series1.name = "Total Instagram Connected";

      chart.scrollbarX = new am4core.Scrollbar();
      // chart.cursor = new am4charts.XYCursor();

      chart.responsive.enabled = true;

      chart.responsive.rules.push({
        relevant: function(target) {
          return false;
        },
        state: function(target, stateId) {
          return;
        }
      });

      this.chart = chart;
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    selectDate(date) {
      this.filters.startDate = new Date(date.startDate).setUTCHours(0, 0, 0).toString();
      this.filters.endDate = new Date(date.endDate).setUTCHours(23, 59, 59).toString();
      this.updateUserStats();
    },
    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() > new Date();
      }
      return classes;
    },
    async updateUserStats() {
      let location_id = null;
      if (this.locObject) { location_id = this.locObject.id }
      this.$apollo
        .query({
          query: USERS_STATS,
          variables: {
            startDay: this.filters.startDate,
            endDay: this.filters.endDate,
            location_id: location_id,
          }
        })
        .then((data) => {
          this.userStatsData = data.data.usersStats.totalUserStats;
          this.chart.data = data.data.usersStats.totalUserStats;
        })
        .catch((e) => {
          this.handleError(e);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
#chartdiv {
  width: 100%;
  height: 500px;
  border: dotted 1px #ddd;
  margin: auto;
}
.filters__item {
  margin-bottom: 10px;
} 
</style>
